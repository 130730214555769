/**
* Generated automatically at built-time (2025-01-22T06:57:21.989Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "black-friday-spis",templateKey: "sites/96-9a0a6e5e-e242-45d0-b302-5048c2fe8e76"};